import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

const RecoverWalletForm = ({ initialValues }) => {
  return (
    <Formik
      initialValues={{
        ...initialValues,
      }}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({ 'form-name': 'recovery-form', ...values }),
        })
          .then(() => {
            // actions.resetForm();
            actions.setStatus(true);
          })
          .finally(() => actions.setSubmitting(false));
      }}
      validate={(values) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const errors = {};
        if (!values["rf-firstname"]) {
          errors["rf-firstname"] = 'First Name Required';
        }
        if (!values["rf-lastname"]) {
          errors["rf-lastname"] = 'Last Name Required';
        }
        if (!values["rf-email"] || !emailRegex.test(values["rf-email"])) {
          errors["rf-email"] = 'Valid Email Required';
        }
        if (!values["rf-confirm"]) {
          errors["rf-confirm"] = 'Please confirm subscription';
        }
        if (!values["rf-messenger"]) {
          errors["rf-messenger"] = 'Handle required';
        }
        return errors;
      }}
    >
      {({ isSubmitting, status }) =>
        !status ? (
          <Form className="form" name="recovery-form" id="recovery-form" data-netlify={true}>
            <div className="form-wrapper row">
              <div className="col-lg-12 text-center mb-lg-4">
                <h4 className="mb-1">Wallet Recovery</h4>
                <p className="mb-4 mb-lg-0">
                  We're here to help! We will get you in contact with the best team in your region.
                </p>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor={`rf-firstname`}>
                    First Name <span className="text-important">*</span>
                  </label>
                  <Field
                    className="input"
                    type="text"
                    name="rf-firstname"
                    id="rf-firstname"
                    placeholder="Nick"
                  />
                  <ErrorMessage
                    name="rf-firstname"
                    render={(msg) => <div className="form-error">{msg}</div>}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor={`rf-lastname`}>
                    Last Name <span className="text-important">*</span>
                  </label>
                  <Field
                    className="input"
                    type="text"
                    name="rf-lastname"
                    id="rf-lastname"
                    placeholder="Gomes"
                  />
                  <ErrorMessage
                    name="rf-lastname"
                    render={(msg) => <div className="form-error">{msg}</div>}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor={`rf-email`}>
                    Email <span className="text-important">*</span>{' '}
                  </label>
                  <Field
                    className="input"
                    type="text"
                    name="rf-email"
                    id="rf-email"
                    placeholder="example@gmail.com"
                  />
                  <ErrorMessage
                    name="rf-email"
                    render={(msg) => <div className="form-error">{msg}</div>}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor={`rf-messenger`}>
                    Messenger <span className="text-important">*</span>{' '}
                  </label>
                  <Field
                    className="input"
                    type="text"
                    name="rf-messenger"
                    id="rf-messenger"
                    placeholder="+12345678901 or t.me/example"
                  />
                  <ErrorMessage
                    name="rf-messenger"
                    render={(msg) => <div className="form-error">{msg}</div>}
                  />
                </div>
              </div>
              <div className="col-lg-6 offset-lg-3 mt-2">
                <div className="form-group">
                  <div className="d-flex align-items-center justify-content-center">
                    <Field
                      className="input"
                      type="checkbox"
                      name="rf-confirm"
                      id="rf-confirm"
                    />
                    <label className="my-0 mx-1" htmlFor={`rf-confirm`}>
                      I agree to be contacted by RecoveryLabs.
                    </label>
                  </div>
                  <ErrorMessage
                    name="rf-confirm"
                    render={(msg) => <div className="form-error m-below">{msg}</div>}
                  />
                </div>
              </div>
              <div className="col-lg-6 offset-lg-3 mt-4">
                <div className="form-group">
                  <button
                    className="button wallet large cta w-100"
                    type="submit"
                  >
                    GET HELP
                  </button>
                </div>
              </div>
            </div>
          </Form>
        ) : (
          <div className="plug">
            <svg
              width={48}
              height={48}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                fill="#5cc0c9"
                d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"
              />
            </svg>
            <p>Thanks for subscription! We will contact you soon!</p>
          </div>
        )
      }
    </Formik>
  );
};

export default RecoverWalletForm;
