import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import MediaElement from '../components/MediaElement';

import logo from '../images/logo.svg';
import marker from '../images/marker.svg';
import email from '../images/email.svg';
import lock from '../images/lock.svg';
import linkedin from '../images/linkedin.svg';
import twitter from '../images/twitter.svg';
import dmitry from '../images/dmitry.jpeg';
// import josh from '../images/josh.jpeg';
// import thomas from '../images/thomas.jpeg';

import Seo from '../components/seo';
import ContactForm from '../components/ContactForm';
import InvestForm from '../components/InvestForm';
import RecoverWalletForm from '../components/RecoverWallet';
import ClientOnly from '../components/ClientOnly';
import '../styles/main.scss';

// markup
const IndexPage = () => {
  const sources = [
    {
      src: 'https://demo.recoverytag.io/assets/videos/recoverytag.mp4',
      type: 'video/mp4',
    },
  ];
  const recovery = [
    {
      src: 'https://demo.recoverytag.io/assets/videos/recovery.mp4',
      type: 'video/mp4',
    },
  ];
  const config = {
    stretching: 'responsive',
  };
  const tracks = {};

  return (
    <>
      <Seo />
      <main>
        <div className="page-section py-5 py-md-7">
          <div className="container">
            <div className="row">
              <div className="col text-center">
                <img className="logo" src={logo} alt="RecoveryTag" />
              </div>
            </div>
          </div>
        </div>
        <div className="page-section py-5 py-md-10 gray">
          <div className="container">
            <div className="row mb-3 mb-sm-5">
              <div className="col text-center">
                <h3>What is RecoveryTag?</h3>
              </div>
            </div>
            <div className="row mb-10">
              <div className="col-lg-12">
                <ClientOnly>
                  <MediaElement
                    id="player1"
                    mediaType="video"
                    preload="none"
                    controls
                    poster="https://demo.recoverytag.io/assets/videos/recoverytag.png"
                    sources={JSON.stringify(sources)}
                    options={JSON.stringify(config)}
                    tracks={JSON.stringify(tracks)}
                  />
                </ClientOnly>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <InvestForm
                  initialValues={{
                    "if-firstname": '',
                    "if-lastname": '',
                    "if-email": '',
                    "if-messenger": '',
                    "if-confirm": false,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="page-section py-5 py-md-10">
          <div className="container">
            <div className="row mb-3 mb-sm-5">
              <div className="col text-center">
                <h3>What is Wallet Recovery?</h3>
              </div>
            </div>
            <div className="row mb-10">
              <div className="col-lg-12">
                <ClientOnly>
                  <MediaElement
                    id="player2"
                    mediaType="video"
                    preload="none"
                    controls
                    poster="https://demo.recoverytag.io/assets/videos/recovery.png"
                    sources={JSON.stringify(recovery)}
                    options={JSON.stringify(config)}
                    tracks={JSON.stringify(tracks)}
                  />
                </ClientOnly>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <RecoverWalletForm
                  initialValues={{
                    "rf-firstname": '',
                    "rf-lastname": '',
                    "rf-email": '',
                    "rf-messenger": '',
                    "rf-confirm": false,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="page-section py-4 py-md-10 dark">
          <div className="container">
            <div className="row text-center mb-5">
              <div className="col">
                <h3>Links and More</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 mb-4 mb-lg-0">
                <a
                  className="card"
                  href="https://keylabs.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="card-top">
                    <StaticImage src="../images/keylabs-logo.png" alt="Keylabs" />
                  </div>
                  <div className="card-bottom">
                    <div>
                      <h4 className="mb-3">Keylabs Audits</h4>
                      <p>
                        Secure Blockchain Solutions, Cryptocurrency Consulting
                        and Blockchain Pentesting.
                      </p>
                    </div>
                    <button
                      className="button primary medium w-100 mt-3"
                      type="button"
                    >
                      See more
                    </button>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 mb-4 mb-lg-0">
                <a
                  className="card"
                  href="https://ast.fyi/wallet"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="card-top">
                    <StaticImage
                      src="../images/live-wallet-recovery-intro.jpg"
                      alt="Wallet Hacking Training"
                    />
                  </div>
                  <div className="card-bottom">
                    <div>
                      <h4 className="mb-3">Advanced Security Training</h4>
                      <p>
                        Our world-renowned training on hardware wallet recovery.
                        Recommended for all wallet engineering teams.
                      </p>
                    </div>
                    <button
                      type="text"
                      className="button primary medium w-100 mt-3"
                    >
                      See more
                    </button>
                  </div>
                </a>
              </div>
              <div className="col-lg-4">
                <a
                  className="card"
                  href="https://youtu.be/Y1OBIGslgGM"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="card-top">
                    <StaticImage
                      src="../images/wallet-fail-talk.png"
                      alt="Wallet.Fail Talk"
                    />
                  </div>
                  <div className="card-bottom">
                    <div>
                      <h4 className="mb-3">Wallet.Fail Talk</h4>
                      <p>
                        Our talk on the (in-)security of popular consumer Hardware Wallets at 35c3.
                      </p>
                    </div>
                    <button
                      className="button primary medium w-100 mt-3"
                      type="button"
                    >
                      See more
                    </button>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer className="footer py-4">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2">
              <div className="footer-links">
                <a className="d-flex align-items-center" href="https://recoverylabs.io/" target="_blank" rel="noopener noreferrer">
                  <img src={marker} width={20} height={20} alt="Location" />
                  RECOVERYLABS
                </a>
                <a className="d-flex align-items-center" href="mailto:hello@recoverylabs.io">
                  <img src={email} width={20} height={20} alt="Email" />
                  HELLO@RECOVERYLABS.IO
                </a>
                <a className="d-flex align-items-center" href="/privacy">
                  <img src={lock} width={20} height={20} alt="Privacy Policy" />
                  PRIVACY POLICY
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default IndexPage;
